<template>
   <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/mtn_logo.png" style="height: 140px;width: 40%;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3" style="color: #000;"> إلغاء الإشتراك </h5>
              </div>
              <form  @submit.prevent="unsubscribe()">
                <!-- <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">تسجيل الدخول</button> -->
                <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">
                <span v-if="loading" class="px-1 arabickufi">جاري الغاء الاشتراك</span> &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="arabickufi">الغاء الاشتراك</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { HTTPMTN } from '@/Api/http-mtn';
import { useToast } from "vue-toastification";
export default {
   name: 'AppUnsubscribes',
   data() {
     return {
       loading: false,
     };
   },
   methods: {
        goBack() {
           return this.$router.go(-1)
        },
      async unsubscribe () {
        const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
          this.loading = true;
        await HTTPMTN.post(`UnSub.php?misidn=${this.$cookie.getCookie("msisdn")}&shortCode=9188&key=C2`).then((response) => {
          if (response.data.status == 0) {
            toast.success("تم الغاء اشتراكك من منصة الالعاب", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')            
          } else {
            toast.error("انت لست مشترك في المنصة ", { timeout: 2000 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style scoped>
.btn-warning {
  color: #fff;
  border-color: #fff;
  background: rgb(0, 0, 0) none repeat scroll 0% 0% !important
}

.bg-dark {
  background: linear-gradient(0deg, #FC0 0, #FC0);
}
</style>